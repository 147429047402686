import cn from 'classnames';

import ProductDemo from '@/components/brochureV2/section/ProductDemo/ProductDemo';
import {type ProductDemoProps} from '@/components/brochureV2/section/ProductDemo/types';

export default function PosProductDemo({
  allProductsDemoClass,
  ...props
}: ProductDemoProps) {
  return (
    <ProductDemo
      {...props}
      progressBarClass="border-lime-70"
      progressBarHoverClass="md:hover:border-lime-70"
      mobileProgressBarClass="bg-lime-70"
      allProductsDemoClass={cn(
        `bg-lime-90 text-shade-20 [&_h3]:text-white`,
        allProductsDemoClass,
      )}
      columnTwoClass="my-0 md:my-0"
    />
  );
}
